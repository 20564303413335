import React from "react"
import Log from "./../../../templates/log.js"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import { Caption, FirstP } from "./../../../components/helpers.js"

export default ({ data, pageContext }) => {

    const content = <>

        <FirstP>After a little bit more than a year I am happy to announce that the project is finally finished. And I know I've said that before, 
        but this time it is really true. The latest version of the circuit board has been running like a charm, no noise, and no thermal shutdowns.
            Since I received a lot of interest during the development I have added a shop section to my website in case you would like to <Link to="/shop/bmw-bluetooth-adapter">buy</Link> one.</FirstP>
         
        <p>
            There is not a lot of content remaining for this post, since I said it all in the introduction. Instead I will show some pictures of the different iterations.
            I'll be honest, in the beginning I expected to need less iterations, but it has been a great project to learn a lot of new things. Before I started, I had only ever 
            designed one simple PCB, and this project pushed my skills in this area a lot, in part because of the thermal and audio quality challenges.         
        </p>

        <Img fadeIn={false} fluid={data.img3.childImageSharp.fluid} alt="Iterations" />
        <Caption>The different iterations of the design.</Caption>

        <p>
            As a recap: I struggled to solder the QFN chip on the first PCB, which is why I only discovered the wrong audio connections and overheating in the second design.
            The third and fourth design fixed the overheating by using a switching regulator, but introduced alternator whine during playback. For the final version I 
            reverted back to a linear regulator, but chose a different design that can deal with the heat better. The PCB will therefore still become quite hot to the touch
            but it works reliably for hours on an end, even when pushing the input voltage to 16V.
        </p>

        <Img fadeIn={false} style={{ marginBottom: '1em' }} fluid={data.img1.childImageSharp.fluid} alt="Final result" />

        <Img fadeIn={false} fluid={data.img4.childImageSharp.fluid} alt="Final result" />

        <Caption>The end product</Caption>

        <p>Finally, I printed a housing and created a cable to make it to a kit that is easy to install in the car, and this wraps up this project! 
            If you have a BMW radio to which this product might fit, please check it out in the <Link to="/shop/bmw-bluetooth-adapter">shop</Link>. In any case, thanks for following along with this project,
            and if you want to talk about it, please use the contact button above.
        </p>
    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "DSCF5623.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    
    img3: file(relativePath: { eq: "DSCF5616.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img4: file(relativePath: { eq: "DSCF5666-3.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`
